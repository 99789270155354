<template>
  <div>
    <Drawer
      :mask-closable="false"
      :transfer="false"
      @on-close="close"
      class="bk-sub-drawer stockIn-drawer"
      title="详情"
      v-model="flag"
      width="600"
    >
      <Spin class="bk-loading" fix v-if="loading">
        <div>
          <Icon type="ios-loading"></Icon>
          <div>Loading</div>
        </div>
      </Spin>
      <Spin class="bk-failed" fix v-if="failed">
        <div @click="req">
          <Icon type="md-refresh"></Icon>
          <p>Refresh</p>
        </div>
      </Spin>
      <div class="bk-drawer-body">
        <Form :label-width="100" @submit.native.prevent inline>
          <FormItem label="单据编号：">
            <div>{{formd.BillCode}}</div>
          </FormItem>
          <FormItem label="单据日期：">
            <div>{{formd.BillDate?formd.BillDate.substring(0, 10):""}}</div>
          </FormItem>
          <FormItem label="仓库：">
            <div>{{storage[formd.StoreId]}}</div>
          </FormItem>
          <FormItem label="入库类型：">
            <div>{{$designConst.stockIn.Type[formd.Type]}} - {{$designConst.stockIn.SubType[formd.SubType]}}</div>
          </FormItem>
          <FormItem label="入库申请人：">
            <div>{{formd.ApplyUserName}}</div>
          </FormItem>
          <FormItem label="审核人：" v-if="formd.Status==2||formd.Status==3">
            <div>{{formd.CreateUserName}}</div>
          </FormItem>
          <FormItem label="审核时间：" v-if="formd.Status==2||formd.Status==3">
            <div>{{formd.CheckTime}}</div>
          </FormItem>
          <FormItem label="审核意见：" v-if="formd.Status==2||formd.Status==3">
            <div>{{formd.CheckOpinion}}</div>
          </FormItem>

          <FormItem class="long" label="备注：">
            <div>{{formd.Remark}}</div>
          </FormItem>
          <FormItem label="入库清单：" style="margin:0;"></FormItem>
        </Form>
        <div :key="i" class="goods-item" v-for="(item, i) in items">
          <div @click="viewImgs(i)" class="img-box">
            <img :src="item.Img1" alt />
            <span>{{item.imgCount}}张</span>
          </div>
          <div class="flex-sub">
            <div class="text-xs">
              名称：{{item.Name}}{{item.Type==1?`(${item.Code})`:""}}，入库数量：{{item.Qty}}
              {{item.GoodsNo?"，内部编号："+item.GoodsNo:""}}
            </div>
            <div class="text-xs">
              <span>电压：{{item.Voltage}}；</span>
              <span>内阻：{{item.InternalResistance}}；</span>
              <span>外观：{{item.Exterior}}；</span>
              <span>气味：{{item.Smell}}；</span>
            </div>
            <div class="text-xs">
              其他说明：
              <span v-if="item.Remark">{{item.Remark}}</span>
              <span class="text-off" v-else>无</span>
            </div>
          </div>
        </div>
        <Form :label-width="100" @submit.native.prevent inline v-if="formd.Status==1">
          <FormItem class="long" label="审核意见：">
            <Input type="textarea" v-model="checkObj.Opinion" />
          </FormItem>
        </Form>
      </div>
      <div class="bk-drawer-footer" v-if="formd.Status==1">
        <Button
          :disabled="posting"
          :loading="posting&&checkObj.Status==1"
          @click="check(1)"
          type="primary"
        >同意</Button>
        <Button
          :disabled="posting"
          :loading="posting&&checkObj.Status==2"
          @click="check(2)"
          type="error"
        >不同意</Button>
      </div>
    </Drawer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      flag: false,
      id: 0,
      loading: false,
      failed: false,
      formd: {},
      items: [],
      cols: [
        { title: "测试项目", key: "ItemName" },
        { title: "名称", key: "Name" },
        { title: "内部编号", key: "GoodsNo" },
        { title: "原编号", render: (h, params) => h(params.row.Code || "--") },
        {
          title: "类型",
          render: (h, params) => {
            return h(
              "span",
              this.$designConst.goods.Type[params.row.Type] +
                " - " +
                this.$designConst.goods.SubType[params.row.SubType]
            );
          },
        },
        { title: "数量", key: "Qty" },
        { title: "入库" },
        { title: "电压", key: "Voltage" },
        { title: "内阻", key: "InternalResistance" },
        { title: "外观", key: "Exterior" },
        { title: "气味", key: "Smell" },
      ],
      storage: {},
      checkObj: {},
      posting: false,
    };
  },
  methods: {
    open(id) {
      this.flag = true;
      this.id = id;
      this.req();
      this.reqStorages();
    },
    close() {
      this.flag = false;
      this.loading = false;
      this.failed = false;
      this.formd = {};
      this.id = 0;
      this.posting = false;
      this.checkObj = {};
    },
    req() {
      this.loading = true;
      this.failed = false;
      this.$axios
        .get("/Api/WmsStockIn/GetVo/" + this.id)
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.formd = res.data.Main;
            let items = [];
            for (let i in res.data.Items) {
              let a = res.data.Items[i];
              a.imgCount = 0;
              if (a.Img1) {
                a.imgCount++;
              }
              if (a.Img2) {
                a.imgCount++;
              }
              if (a.Img3) {
                a.imgCount++;
              }
              if (a.Img4) {
                a.imgCount++;
              }
              if (a.Img5) {
                a.imgCount++;
              }
              items.push(a);
            }
            this.items = items;
          } else {
            this.failed = true;
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.loading = false;
          this.failed = true;
          this.$Message.error(err.errormsg);
        });
    },
    reqStorages() {
      this.$axios.get("/Api/WmsStorage/QueryAll").then((res) => {
        if (res.result) {
          let obj = {};
          res.data.map((v) => {
            obj[v.Id] = v.Name;
          });
          this.storage = obj;
        }
      });
    },
    viewImgs(i) {
      let imgs = [];
      if (this.items[i].Img1) imgs.push(this.items[i].Img1);
      if (this.items[i].Img2) imgs.push(this.items[i].Img2);
      if (this.items[i].Img3) imgs.push(this.items[i].Img3);
      if (this.items[i].Img4) imgs.push(this.items[i].Img4);
      if (this.items[i].Img5) imgs.push(this.items[i].Img5);
      this.$root.$emit("view-imgs", imgs);
    },
    check(status) {
      if (status == 2 && !this.checkObj.Opinion) {
        this.$Message.warning("请输入审核意见");
        return;
      }
      this.checkObj.Id = this.formd.Id;
      this.checkObj.Status = status;
      this.posting = true;
      this.$axios
        .post("/Api/WmsStockIn/Review", this.checkObj)
        .then((res) => {
          this.posting = false;
          if (res.result) {
            this.$Message.success("操作成功");
            this.req();
            this.$emit("on-upd");
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
  },
};
</script>
<style lang="less">
.stockIn-drawer {
  .ivu-form-item {
    width: 50%;
    margin: 0;
  }
  .ivu-form-item.long {
    width: 100%;
  }
  .ivu-form-item-label {
    color: #808695;
  }
  .goods-item {
    display: flex;
    padding: 5px;
    border: 1px solid #dcdee2;
    border-radius: 4px;
    margin-bottom: 10px;
    .img-box {
      display: block;
      width: 58px;
      height: 58px;
      border-radius: 4px;
      flex-shrink: 0;
      margin-right: 5px;
      position: relative;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
      }
      img:hover {
        box-shadow: 0 0 0 2px rgba(45, 140, 240, 0.2);
      }
      span {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 2px;
        border-bottom-left-radius: 4px;
        color: #fff;
        background: rgba(0, 0, 0, 0.3);
        font-size: 12px;
      }
    }
  }
}
</style>