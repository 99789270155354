<template>
  <div class="bk-page" id="stockIn">
    <div class="bk-page-scroll">
      <div class="bk-page-lst">
        <Form :label-width="70" @submit.native.prevent class="bk-page-search" inline>
          <FormItem label="委托单">
            <Input :value="searchData1.OrderCode" @on-clear="unPickOrder" clearable readonly>
              <Button @click="toPickOrder" icon="ios-more" slot="append"></Button>
            </Input>
          </FormItem>
          <FormItem label="状态">
            <Select clearable v-model="searchData1.Status">
              <Option :value="1">待审核</Option>
              <Option :value="2">审核通过</Option>
              <Option :value="3">审核不通过</Option>
            </Select>
          </FormItem>
          <FormItem label="类型">
            <Select clearable v-model="searchData1.SubType">
              <Option :value="1">接收入库</Option>
              <Option :value="2">检毕入库</Option>
              <Option :value="99">其它入库</Option>
            </Select>
          </FormItem>
          <FormItem label="单据号">
            <Input v-model="searchData1.BillCode" />
          </FormItem>
          <FormItem label="单据时间">
            <DatePicker v-model="searchData1.BillDate" />
          </FormItem>
          <FormItem class="btns">
            <Button @click="onSearch" type="primary">搜索</Button>
            <Button @click="reSearchData" type="default">重置</Button>
            <Button @click="toAdd" ghost type="primary" v-if="$able.map.WmsStockInAdd">
              <Icon type="md-add" />新增
            </Button>
          </FormItem>
        </Form>
        <Table :columns="tableCols" :data="tableData" :loading="tableLoading"></Table>
        <Page
          :current="pageIndex"
          :page-size="pageSize"
          :total="recordCount"
          @on-change="(index) => onChangePage(index, 0)"
          @on-page-size-change="(size) => onChangePage(1, size)"
          show-elevator
          show-sizer
          show-total
          transfer
        />
      </div>
    </div>
    <dtl @on-upd="reqTable" ref="dtl" />
    <edit @on-add="onChangePage(1)" @on-upd="reqTable" ref="edit" />
  </div>
</template>
<script>
import PickOrder from "../../components/PickOrder.vue";
import dtl from "./stockIn-dtl";
import edit from "./stockIn-edit";
export default {
  components: { PickOrder, dtl, edit },
  data() {
    return {
      searchData1: {},
      searchData2: {},
      // ——————————
      tableLoading: false,
      tableCols: [
        { title: "委托单", key: "OrderCode", width: 150 },
        {
          title: "状态",
          key: "Status",
          width: 110,
          render: (h, params) => {
            return h("Badge", {
              props: {
                status:
                  this.$designConst.stockIn.StatusBadge[params.row.Status],
                text: this.$designConst.stockIn.Status[params.row.Status],
              },
            });
          },
        },
        {
          title: "单据",
          render: (h, params) => {
            return [
              h("div", params.row.BillCode),
              h(
                "div",
                { attrs: { class: "text-sub text-xs" } },
                params.row.BillDate.substring(0, 10)
              ),
            ];
          },
        },
        {
          title: "类型",
          render: (h, params) => {
            return h(
              "span",
              // this.$designConst.stockIn.Type[params.row.Type] +
              //   " - " +
              this.$designConst.stockIn.SubType[params.row.SubType]
            );
          },
        },
        { title: "申请人", key: "ApplyUserName" },
        { title: "审核人", key: "CheckUserName" },
        { title: "审核时间", key: "CheckTime" },
        { title: "审核意见", key: "CheckOpinion", tooltip: true },
        {
          title: "操作",
          width: 120,
          align: "right",
          render: (h, params) => {
            let a = [];
            let reviewABLE =
              this.$able.map.WmsStockInReview && params.row.Status == 1;
            let editABLE =
              this.$able.map.WmsStockInUpd &&
              (params.row.Status == 1 || params.row.Status == 0);
            if (reviewABLE) {
              a.push(
                h(
                  "span",
                  {
                    style: { cursor: "pointer", color: "#2d8cf0" },
                    on: {
                      click: () => {
                        this.$refs.dtl.open(params.row.Id);
                      },
                    },
                  },
                  "审核"
                )
              );
              a.push(h("Divider", { props: { type: "vertical" } }));
            }
            if (!reviewABLE || editABLE) {
              a.push(
                h("Icon", {
                  props: { type: editABLE ? "md-create" : "md-open", size: 18 },
                  attrs: editABLE ? { title: "编辑" } : { title: "详情" },
                  style: { cursor: "pointer", color: "#2d8cf0" },
                  on: {
                    click: () => {
                      editABLE
                        ? this.$refs.edit.open(params.row.Id)
                        : this.$refs.dtl.open(params.row.Id);
                    },
                  },
                })
              );
              a.push(h("Divider", { props: { type: "vertical" } }));
            }
            if (this.$able.map.WmsStockInDel) {
              a.push(
                h("Icon", {
                  props: { type: "md-close", size: 18 },
                  attrs: { title: "删除" },
                  style: { cursor: "pointer", color: "#ed4014" },
                  on: {
                    click: () => {
                      this.toDel(params.row);
                    },
                  },
                })
              );
            }
            return a;
          },
        },
      ],
      tableData: [],
      // ——————————
      recordCount: 0,
      pageIndex: 1,
      pageSize: 10,
    };
  },
  activated: function () {
    console.info("激活：", this.$tab.conf[this.$route.name].label);
    this.$root.$off("on-refresh");
    this.$root.$on("on-refresh", () => {
      this.init();
    });
    if (this.$route.params.re == 1) {
      this.init();
    }
  },
  methods: {
    init() {
      if (this.$refs.dtl.flag) {
        this.$refs.dtl.close();
      }
      if (window.toTabData && window.toTabData.action == "toStockInAfterTest") {
        this.$refs.edit.open("", "afterTest", {
          BtmsOrderId: window.toTabData.OrderId,
          OrderCode: window.toTabData.OrderCode,
          ItemId: window.toTabData.ItemId
        });
        window.toTabData = null;
      } else if (this.$refs.edit.flag) {
        this.$refs.edit.close();
      }
      if (this.$store.state.order.Id) {
        this.searchData1 = {
          OrderId: this.$store.state.order.Id,
          OrderCode: this.$store.state.order.Code,
        };
        this.pageIndex = 1;
        this.onSearch();
      } else {
        this.initSearch();
      }
    },
    initSearch() {
      this.searchData1 = {};
      this.pageIndex = 1;
      this.onSearch();
    },
    onSearch() {
      let data = this.$util.copy(this.searchData1);
      if (data.BillDate) {
        data.BillDate = this.searchData1.BillDate.format("yyyy-MM-dd");
      }
      this.searchData2 = data;
      this.pageIndex = 1;
      this.reqTable();
    },
    reSearchData() {
      this.searchData1 = {};
    },
    reqTable() {
      if (this.$able.map.WmsStockInQry == undefined) {
        this.$Message.error("无权限查询");
        return;
      }
      let params = Object.assign(
        {
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
        },
        this.searchData2
      );
      this.tableLoading = true;
      this.$axios
        .get("/Api/WmsStockIn/QueryPg", { params: params })
        .then((res) => {
          this.tableLoading = false;
          if (res.result) {
            this.recordCount = res.data.RecordCount;
            this.tableData = res.data.DataSet || [];
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.tableLoading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    onChangePage(index, size) {
      if (index > 0) {
        this.pageIndex = index;
      }
      if (size > 0) {
        this.pageSize = size;
      }
      this.reqTable();
    },
    // 委托单
    toPickOrder() {
      if (this.searchData1.OrderId) {
        this.$refs.PickOrder.open([
          { Id: this.searchData1.OrderId, Code: this.searchData1.OrderCode },
        ]);
      } else {
        this.$refs.PickOrder.open();
      }
    },
    onPickOrder(lst) {
      if (lst[0]) {
        this.searchData1.OrderId = lst[0].Id;
        this.$set(this.searchData1, "OrderCode", lst[0].Code);
      } else {
        this.unPickOrder();
      }
    },
    unPickOrder() {
      this.searchData1.OrderId = "";
      this.$set(this.searchData1, "OrderCode", "");
    },
    // ==========
    toAdd() {
      this.$refs.edit.open();
    },
    toDel(row) {
      this.$Modal.confirm({
        title: "注意",
        content: "是否删除：" + this.$util.color(row.Name),
        onOk: () => {
          this.doDel(row.Id);
        },
      });
    },
    doDel(id) {
      this.$axios
        .post("/Api/WmsStockIn/Delete", { Id: id })
        .then((res) => {
          if (res.result) {
            this.$Message.success("删除成功");
            this.reqTable();
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.$Message.error(err.errormsg);
        });
    },
  },
};
</script>